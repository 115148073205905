<template>
  <div class="page">
    <navbar index="3" sub="2" />

    <!-- banner begain -->
    <banner title="智慧能效及碳足迹管理分析平台">
      <img src="../../assets/img/banner_pro_3.jpg" class="img-fluid" alt="">
    </banner>
    <!-- banner end -->

    <div class="main-content">

        <!-- 产品介绍 begain -->
        <div class="intro-box">
          <div class="container-fluid intro-content">
            <div class="intro-img">
              <img src="../../assets/img/pro_3_1.jpg" class="img-fluid" alt="">
            </div>
            <div class="intro-txt">
              <div class="intro-title">产品介绍</div>
              <div class="intro-name">智慧能效及碳足迹管理分析平台</div>
              <div class="intro-desc">智慧能效及碳足迹管理分析平台注重能源互联网创新型技术成果研发及应用，运用世界顶级咨询机构能源行业洞察力，致力于推动绿色智慧用能的创新型综合能源服务商。从能源诊断规划，到能源项目的投资、开发与运营，再到能源交易托管，为客户提供端到端的综合能源服务。</div>
            </div>
          </div>
        </div>
        <!-- 产品介绍 end -->

        <!-- 解决方案 begain -->
        <div class="solution-box">
          <section-title title="THE SOLUTION／解决方案 " bar-color="#00B0FF"/>
          <div class="container-fluid solution-content">
            <div class="solution-item">
              <div class="text-center">
                <img src="../../assets/img/pro_3_2.jpg" class="img-fluid" alt="">
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>能效管理</h3>
                  </div>
                  <div class="desc">
                    <p>*多类型数据融合的能源数据管理</p>
                    <p>*便携的数据探索与分析工具(支持Al算法)</p>
                    <p>**分布式存储与计算，支持海量数据</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_3_3.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_3_4.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>用电分析</h3>
                  </div>
                  <div class="desc">
                    <p>*通过智慧能效及碳足迹管理分析平台可远程监控园区内各个能源设备的用电情况</p>
                    <p>可以对园区各个能源设备用电参数进行阈值设置，当超过阈值设定，将主动推送报警信息到手机上</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>大屏可视化</h3>
                  </div>
                  <div class="desc">
                    <p>*全方位数字展示效果</p>
                    <p>*方便快捷的访问方式</p>
                    <p>*专业的数据服务能力</p>
                    <p>*数据访问安全</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="solution-img">
                  <img src="../../assets/img/pro_3_5.png" class="img-fluid" alt="">
                </div>
              </div>
            </div>
            <div class="row solution-item">
              <div class="col-5 offset-1">
                <div class="solution-img">
                  <img src="../../assets/img/pro_3_6.png" class="img-fluid" alt="">
                </div>
              </div>
              <div class="col-5 solution-txt">
                <div class="txt-info">
                  <div class="title">
                    <h3>园区总览</h3>
                  </div>
                  <div class="desc">
                    <p>*安全、稳定、敲的云平台</p>
                    <p>*能源管理高级应用</p>
                    <p>*实现能源项目低成本快速部署</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="solution-item">
              <div class="text-center">
                <img src="../../assets/img/pro_3_7.png" class="img-fluid" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- 解决方案 end -->

        <!-- 服务技术 begain -->
        <div class="advant-box">
          <section-title title="SERVICE TECHNOLOGY/服务技术 " bar-color="#00B0FF"/>
          <div class="container-fluid">
            <div class="row">
              <div class="col-10 offset-1 advant-content">
                <div class="advant-item">
                  <img src="../../assets/img/pro_3_8.jpg" class="" alt="">
                  <h3>云计算</h3>
                  <div class="hover-box">
                    <div class="hover-content">
                      <div class="title">云计算</div>
                      <div class="desc">
                        <p>安全、稳定</p>
                        <p>能源管理高级应用</p>
                        <p>实现能源项目低成本快速部看</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_3_9.jpg" class="" alt="">
                  <h3>人工智能</h3>
                  <div class="hover-box">
                    <div class="hover-content">
                      <div class="title">人工智能</div>
                      <div class="desc">
                        <p>用能设备识别</p>
                        <p>异常数据监测</p>
                        <p>高精度负荷预则</p>
                        <p>多种能原</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_3_10.jpg" class="" alt="">
                  <h3>智能物联</h3>
                  <div class="hover-box">
                    <div class="hover-content">
                      <div class="title">智能物联</div>
                      <div class="desc">
                        <p>实现多种类型设备接入</p>
                        <p>支持多种传输方式</p>
                        <p>边缘采集与计算终端</p>
                        <p>实现数据的筛选和处理</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="advant-item">
                  <img src="../../assets/img/pro_3_11.jpg" class="" alt="">
                  <h3>大数据分析</h3>
                  <div class="hover-box">
                    <div class="hover-content">
                      <div class="title">大数据分析</div>
                      <div class="desc">
                        <p>多类型数据融合的能源数据管理</p>
                        <p>便携的数据探索与分析工具(支持A算法)</p>
                        <p>分布式存储与计算，支持海量数据</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 服务技术 end -->
    </div>

    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  export default {
    name: 'Pro3',
    components: {
      navbar,
      banner,
      sectionTitle,
      foot
    }
  }
</script>

<style lang="scss" scoped>
  .intro-box {
    margin: 0 auto;
    padding: 60px 0;
    .intro-content {
      display: flex;
      justify-content: stretch;
      align-items: center;
      padding: 0 15%;
      font-size: 10px;
      font-weight: bold;
      color: #333;
      .intro-img {
        flex: 0 0 30%;
      }
      .intro-txt {
        flex: 1;
        margin-left: 10%;
        .intro-title {
          margin-bottom: .5em;
          font-size: 36px;
        }
        .intro-name {
          margin-bottom: .5em;
          font-size: 32px;
        }
        .intro-desc {
          line-height: 1.8;
          font-size: 24px;
          font-weight: 400;
        }
      }
    }
  }
  .solution-box {
    margin: 0 auto;
    padding: 60px 0;
    .solution-content {
      .solution-item{
        margin-top: 90px;
        .solution-txt {
          display: flex;
          justify-content: space-around;
          .txt-info {
            .title{
              margin-bottom: 30px;
              h3 {
                margin: 0;
                line-height: 1;
                font-size: 30px;
                font-weight: bold;
              }
            }
            .desc {
              p {
                margin: 0;
                line-height: 2;
                font-size: 24px;
                color: #666;
              }
            }
          }
        }
      }
      .solution-img {
        padding-top: 30px;
      }
    }
  }
  .advant-box {
    margin: 0 auto;
    padding: 60px 0;
    .advant-content {
      margin-top: 90px;
      padding-bottom: 90px;
      display: flex;
      justify-content: space-between;
      .advant-item {
        flex: 1;
        margin-right: 40px;
        position: relative;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: auto;
        }
        h3 {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
          width: 100%;
          text-align: center;
          font-size: 24px;
          color: #fff;
          opacity: 1;
        }
        .hover-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          background-color: rgba(255, 255, 255, .4);
          color: #333;
          text-align: center;
          opacity: 0;
          transition: all .5s ease-in;
          .title {
            margin-bottom: 50px;
            font-size: 24px;
            font-weight: bold;
          }
          .desc {
            font-size: 14px;
          }
        }
        &:hover {
          .hover-box {
            opacity: 1;
          }
          h3 {
            opacity: 0;
          }
        }
      }
    }
  }
</style>